<template color="tapiz" class="mx-1">

    <v-row class="mx-1 mt-1" color="tapiz">

        <v-col cols="5" xs="8" sm="5"  md="5"  lg="6" xl="6" class="mt-2">

        <v-icon slot="icon" color="red" size="25" class="mx-2">flag</v-icon>
        <span class="text-subtitle-2 pt-3 mt-3">Vista de eventos de conductor por pais</span>

        <v-chip class="ma-2" color="primary"  label > 
           <v-icon left>account_circle</v-icon>
           conductores  
      {{ totalconductores -1 }} 
    </v-chip>

        

</v-col>

<v-col cols="6" xs="6" sm="7" md="6" lg="6" xl="6" class="float-right text-lg-right text-md-right text-right">
   
   <v-flex class="d-inline-flex pb-0 pt-0">

       <v-select v-model="id_pais_activo" :items="PaisesArray" small  style="width:150px;height:30px;padding-top:0px;" @change="ListarFlotas()"
        label="Pais">
      </v-select>

                   

      <v-tooltip bottom class="d-inline-flex">
          <template v-slot:activator="{ on, attrs }">
        <v-btn class="mx-2 mt-2" icon v-bind="attrs"  v-on="on" small color="primary" @click="dialogInicio=true">
            <v-icon dark>
            today
            </v-icon>
          </v-btn>

          </template>
      <span>Cambio fechas</span>
      </v-tooltip>

      <v-btn color="info" text outlined  @click="CambiarVistaJornada()" small class="mt-1"><v-icon>work</v-icon>Jornadas</v-btn>


      <v-tooltip bottom class="float-right">
              <template v-slot:activator="{ on, attrs }">
                <v-btn icon  v-bind="attrs"  v-on="on" dark color="indigo" @click="dialogExit=true"> 
                  <v-icon dark>logout</v-icon>
                </v-btn>
              </template>
              <span>Salir de aplicacion</span>
          </v-tooltip>



    </v-flex>
 </v-col>


 
 <v-dialog v-model="dialogInicio" persistent max-width="280">
      <v-card>
         <v-toolbar flat color="fondo1" class="text-center">
        <v-toolbar-title class="white--text  text-center"><strong>Eventos por fechas</strong></v-toolbar-title>
      </v-toolbar>

        <v-card-text>

           <v-flex>

                    <v-menu
                      ref="menuFecha1"
                      v-model="menuFecha1"
                      :close-on-content-click="false"
                      :return-value.sync="date"
                      transition="scale-transition"
                  
                      offset-y
                      min-width="290px"
                    >
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                              v-model="fecha_inicio"
                              label="Fecha inicio"
                              prepend-icon="event"
                              readonly
                              v-bind="attrs"
                              v-on="on"
                            ></v-text-field>
                          </template>
                          <v-date-picker v-model="fecha_inicio" locale="es"
                          no-title  @input="menuFecha1 = false" scrollable>
                          </v-date-picker>
                    </v-menu>
              </v-flex>

          <v-flex>
                 <v-menu
                  ref="menuFecha2"
                  v-model="menuFecha2"
                  :close-on-content-click="false"
                  :return-value.sync="date"
                  transition="scale-transition"
              
                  offset-y
                  min-width="290px"
                >
                      <template v-slot:activator="{ on, attrs }">
                        <v-text-field
                          v-model="fecha_fin"
                          label="Fecha fin"
                          prepend-icon="event"
                          readonly
                          v-bind="attrs"
                          v-on="on"
                        ></v-text-field>
                      </template>
                      <v-date-picker v-model="fecha_fin" locale="es"
                       no-title  @input="menuFecha2 = false" scrollable>
                      </v-date-picker>
                </v-menu>

              </v-flex>

           
                <v-flex justify-center d-flex>
                  
                      <v-btn color="primary" dark @click="Procesar()" style="margin-right:10px;">Generar</v-btn>
                    <v-btn  @click="dialogInicio=false" style="margin-right:10px;">Cancelar</v-btn>

               </v-flex>
   
        </v-card-text>
       
      </v-card>
   </v-dialog>

   <v-dialog v-model="dialogExit"  hide-overlay
                     persistent
                     width="250">
                        <v-card>
                          <v-card-title>Salir de aplicacion</v-card-title>
                          <v-card-text>Deseas salir?</v-card-text>
                          <v-card-actions class="justify-space-around">
                            <v-btn @click="dialogExit=false" text>Cancelar</v-btn>
                          <v-btn @click="cerrar()" dark color="indigo">Salir</v-btn></v-card-actions>
                        </v-card>
  </v-dialog>


  
    <!--- exceso de velocidad -->

    <v-col xs="12" sm="12" md="6" lg="6" xl="6">

      <v-card  elevation="4" class="mt-n3 pt-0" style="padding-bottom:-30px;">
        <v-card-title class="pb-md-0 mb-md-0">
          <span>Exceso de velocidad</span> 

          <v-chip color="info" small  class="ml-3 mr-3">{{ numeroExcesos }}</v-chip>

          <v-btn  color="primary" small @click="dialogExceso=true"  rounded>Detalle</v-btn>
                      <v-spacer></v-spacer>
                     <v-icon>speed</v-icon>
         
      </v-card-title>

        <v-card-text>

          <v-row class="pa-0 pb-0">

                <v-col cols="4" v-for="(itemseveridad, index) in CategoriaGlobalExces" :key="`severidad-${index}`" class="pa-0 pb-0 pr-0">

                  <v-list-item class="pb-0 pt-0 pr-0">
                  <v-list-item-avatar class="pb-0 pt-0 pr-0">
                      
                    <v-avatar size="20" :color="itemseveridad.color" rounded  class="elevation-1">
                          <v-icon dark color="white"  small>{{ itemseveridad.icono }}</v-icon>
                      </v-avatar>
                    </v-list-item-avatar>

                    <v-list-item-content class="pb-0 pt-0 pl-0">

                        <v-list-item-title class="caption">{{ itemseveridad.nombre }}</v-list-item-title>
                        <v-list-item-subtitle class="caption">{{ itemseveridad.valor }}</v-list-item-subtitle>
                    </v-list-item-content>

                  </v-list-item>

                </v-col>

            <div id="chart" class="pt-0 pb-0 mt-0" style="margin-top:-23px">
            <apexchart type="bar"  height="320" :options="chartOptionsSeveridad" style="margin-top:-23px" :series="seriesSeveridad"></apexchart>
            </div>

          </v-row>

            </v-card-text>
            </v-card>
    </v-col>





     <!---Dialogo Excesos de velocidad-->
     <v-dialog v-model="dialogExceso" max-width="1000px">
          <v-card>

              <v-toolbar color="fondo1">
              <v-toolbar-title class="white--text">
              Detalle de Excesos de velocidad
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-icon dark @click="dialogExceso=false">close</v-icon>
            </v-toolbar>



            <v-card-text>
              <v-container>
                 <v-row>


                  <v-col  cols="3" sm="4" md="4" lg="3" xl="3">
                      <v-select v-model="id_conductor_vista_exceso" :items="SelectConductor" small  outlined @change="FiltrarExcesosCond()"   label="Conductor" class="mt-2">
                      </v-select> 
                    </v-col>

                   <v-col  cols="4" sm="12" md="4">
                    <v-text-field
                        v-model="searchExcesos"
                        append-icon="search"
                        label="Busqueda"
                        single-line
                        hide-details
                      ></v-text-field>
                  </v-col>


                    <v-col cols="12" sm="12" md="12">

                        <div class="align-center text-md-center mb-2">
                            <span class="h5 align-center"><strong>Detalle de Excesos de velocidad</strong></span>
                        </div>
                        <v-divider></v-divider>
                        
                            <v-data-table
                            :headers="headersExcesos"
                            :items="datosTablaExcesos"
                            :items-per-page="5"
                            :search="searchExcesos"
                            class="elevation-1">

                               <template v-slot:[`item.ubicacion`]="{ item }">
                                      <v-btn @click="openGoogleMap(item)" color="indigo" icon><v-icon dark>map</v-icon></v-btn>
                                      </template>
                        

                           
        
                        </v-data-table>

                                  

                    </v-col>


                </v-row>
                
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogExceso=false">
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
    </v-dialog>





       <!--- Botones de panico --> 

       <v-col xs="12" sm="12" md="6" lg="6" xl="6" class="mt-2">
      <v-card  elevation="4" class="mt-n3 pt-0" style="padding-bottom:-30px;">
        <v-card-title class="pb-md-0 mb-md-0">
          
          <span>Botones de panico</span> 

          <v-chip color="red" small  dark class="mx-2">{{ numeroBotones }}</v-chip>
            <v-spacer></v-spacer>     
            <v-btn  color="red" dark small @click="dialogBotones=true"  rounded>Detalle</v-btn>

   </v-card-title>

        <v-card-text>

          <v-row>
            <v-col cols="6" md="6" class="d-flex align-center">
              <v-avatar size="25" color="red"  rounded class="elevation-1">
                <v-icon dark color="white" small>campaign</v-icon>
              </v-avatar>
              <div class="ms-3">
                <p class="text-xs mb-0 text-caption">
                  Botones total
                </p>
                <h3 class="text-xl font-weight-semibold">
                  {{ numeroBotones }}
                </h3>
              </div>
            </v-col>

            </v-row>

          <div id="chart" class="pt-md-n2 mt-md-0 pb-md-0">
            <apexchart type="bar"  height="250" :options="chartOptionsBotones" class="pt-md-n4 mt-md-n4 pb-md-n5 mb-md-n5" :series="seriesBotones"></apexchart>
            </div>
            </v-card-text>
            </v-card>
            </v-col>



             <!---Dialogo botones-->
   <v-dialog v-model="dialogBotones" max-width="1000px">
          <v-card>

              <v-toolbar color="fondo1">
              <v-toolbar-title class="white--text">
              Detalle de botones de panico
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-icon dark @click="dialogBotones=false">close</v-icon>
            </v-toolbar>

            <v-card-text>

               <v-row>

         
                      <v-col  cols="5" sm="12" md="5">
                        <v-text-field class="mx-5"
                          v-model="searchBotones"
                          append-icon="search"
                          label="Busqueda"
                          single-line
                          hide-details
                        ></v-text-field>
                    </v-col>


                </v-row>





                 <v-data-table
                  :headers="headersBotones"
                  :items="ArrayBotones"
                  :search="searchBotones"
                  sort-by="calories"
                  class="elevation-1 mt-5"
                >
              

                <template v-slot:[`item.fecha`]="{ item }">
                            <span>{{ getDate(item.fecha) }}</span>
                </template>

                <template v-slot:[`item.ubicacion`]="{ item }">
                <v-btn @click="openGoogleMap(item)" color="indigo" icon><v-icon dark>map</v-icon></v-btn>
              </template>


        
            </v-data-table>



            </v-card-text>
                 

          </v-card>
   </v-dialog>







    <!--- Graficas de ralenti  --> 

    <v-col xs="12" sm="12" md="6" lg="6" xl="6" class="mt-n2">
      <v-card  elevation="4" class="mt-1 pt-0" style="padding-bottom:-30px;">
        <v-card-title class="pb-md-0 mb-md-0"><span>Horas Ralenti</span> 
        <v-btn color="secondary" class="ml-7 pb-md-0"  dark small @click="dialogRalenti=true">Detalle</v-btn>
        <v-spacer></v-spacer><v-icon>timer</v-icon></v-card-title>
        <v-card-text class="pa-md-0 mt-md-0 pt-md-n2 pb-md-n5">
          <div id="chart" class="pt-md-n2 mt-md-0 pb-md-0">
            <apexchart type="bar" :height="heightRalenti" :options="chartOptionsRalenti" class="pt-md-n4 mt-md-n4 pb-md-n5 mb-md-n5" :series="seriesRalenti"></apexchart>
            </div>
            </v-card-text>
            </v-card>
            </v-col>




<!---Dialogo ralenti-->
<v-dialog v-model="dialogRalenti" max-width="1000px">
          <v-card>

              <v-toolbar color="fondo1">
              <v-toolbar-title class="white--text">
              Detalle de Ralenti
              </v-toolbar-title>
              <v-spacer></v-spacer>
              <v-icon dark @click="dialogRalenti=false">close</v-icon>
            </v-toolbar>



            <v-card-text>
              <v-container>
                 <v-row>


                   <v-col  cols="5" sm="12" md="5">
                    <v-text-field
                        v-model="searchRalenti"
                        append-icon="search"
                        label="Busqueda"
                        single-line
                        hide-details
                      ></v-text-field>
                  </v-col>

                  

                    <v-col cols="12" sm="12" md="12">

                        <div class="align-center text-md-center mb-2">
                            <span class="h5 align-center"><strong>Datos de Ralenti</strong></span>
                        </div>
                        <v-divider></v-divider>
                        
                            <v-data-table
                            :headers="headersRalenti"
                            :items="arregloDatosViajes"
                            :items-per-page="5"
                            :search="searchRalenti"
                            class="elevation-1">

                              <!--template v-slot:[`item.fecha`]="{ item }"> 
                              <span>{{ getDate(item.fecha)}} </span>
                              </template-->


                              <template v-slot:[`item.distancia`]="{ item }"> 
                              <span>{{ item.distancia.toFixed(2)}} </span>
                              </template>




                        </v-data-table>

                                  

                    </v-col>


                </v-row>
                
              </v-container>
            </v-card-text>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="dialogRalenti=false">
                Cerrar
              </v-btn>
            </v-card-actions>
          </v-card>
  </v-dialog>



















    <!--- Graficas de distancias por conductor  --> 
    <v-col xs="12" sm="12" md="6" lg="6" xl="6" class="mt-0">
      <v-card  elevation="4" class="mt-n3 pt-0" style="padding-bottom:-30px;">
        <v-card-title class="pb-md-0 mb-md-0"><span>Distancias por conductor</span> 
       
        <v-spacer></v-spacer></v-card-title>
        <v-card-text class="pa-md-0 mt-md-0 pt-md-n2 pb-md-n5">
          <div id="chart" class="pt-md-n2 mt-md-0 pb-md-0">
            <apexchart type="bar" height="230" :options="chartOptionsKM" :series="seriesKM"></apexchart>
          </div>
            </v-card-text>
            </v-card>
            </v-col>












            
     <!--- Graficas de Excesos de velocidad > 

      <v-col xs="12" sm="12" md="6" lg="6" xl="6" class="mt-2">
      <v-card  elevation="4" class="mt-n3 pt-0" style="padding-bottom:-30px;">
        <v-card-title class="pb-md-0 mb-md-0"><span> Excesos de velocidad</span> 
          <v-spacer></v-spacer></v-card-title>
        <v-card-text class="pa-md-0 mt-md-0 pt-md-n2 pb-md-n5">
          <div id="chart" class="pt-md-n2 mt-md-0 pb-md-0">
            <apexchart type="bar" height="230" :options="chartOptionsExcesos" class="pt-md-n4 mt-md-n4 pb-md-n5 mb-md-n5" :series="seriesExcesos"></apexchart>
          </div>
            </v-card-text>
            </v-card>
            </v-col-->


 





      <v-col xs="12" sm="12" md="12" lg="12" xl="12" class="mt-4 mb-5 mr-3">
      <v-card  elevation="4" class="mt-n3 pt-0" style="padding-bottom:-30px;">
        <v-card-title class="pb-md-0 mb-md-0">
          <span>Detalle viajes por conductores</span> 
         

          <vue-excel-xlsx  class="mx-1" v-show="datosTabla.length >0"
                        :data="datosTabla"
                        :columns="column_excel"
                        :filename="'Historial_viajes_conductor'"
                        :sheetname="'hoja1'">
                        <v-btn color="info">
                            <v-icon dark>get_app</v-icon>
                        </v-btn>
                        
                        </vue-excel-xlsx>

              <v-col  cols="3" sm="4" md="4" lg="3" xl="3">
                      <v-select v-model="id_conductor_vista" :items="SelectConductor" small  outlined @change="FiltrarViajes()"   label="Conductor" class="mt-2">
                      </v-select> 
                    </v-col>

                    <v-spacer></v-spacer>


              <v-text-field class="mx-5"
                  v-model="search"
                  append-icon="search"
                  label="Busqueda"
                  single-line
                  hide-details
                ></v-text-field>
         
      </v-card-title>

        <v-card-text class="pa-md-0 mt-md-0 pt-md-n2 pb-md-n5">


          <v-data-table
    :headers="headers"
    :items="datosTabla"
    :search="search"
    sort-by="calories"
    class="elevation-1"
  >


  <!--template v-slot:[`item.fecha`]="{ item }">
                  <span>{{ getDate(item.fecha) }}</span>
       </template-->

       <template v-slot:[`item.distancia`]="{ item }"> 
                              <span>{{ item.distancia.toFixed(2)}} </span>
                              </template>


</v-data-table>

        </v-card-text>
            </v-card>
            </v-col>


</v-row>


    
</template>

<script>

import axios from 'axios';

import { mapState,mapActions } from "vuex";

import { mapMutations } from "vuex";

import VueApexCharts from 'vue-apexcharts';

export default {
  components:{
    apexchart: VueApexCharts
    
    },
     data: () =>  ({
            id_pais_activo:'',
            PaisesArray:[],

            menuFecha1:false,
            fecha_inicio:new Date().toISOString().substr(0, 10),
            menuFecha2:false,
            fecha_fin:new Date().toISOString().substr(0, 10),
            date: new Date().toISOString().substr(0, 10),
            dialogInicio:false,
            dialogExit:false,

            
             //datos de km

             chartOptionsKM:  {
            chart: {
              type: 'bar',
              height: 230
            },
            plotOptions: {
              bar: {
                horizontal: false,
                columnWidth: '55%',
                endingShape: 'rounded'
              },
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              show: true,
              width: 2,
              colors: ['transparent']
            },
            xaxis: {
              categories: ['vacio',],
            },
            yaxis: {
              title: {
                text: 'Kilometros'
              }
            },
            fill: {
              opacity: 1
            },
            tooltip: {
              y: {
                formatter: function (val) {
                  return "  " + val + " "
                }
              }
            }
          },
            
              seriesKM:[{
                              name: 'Distancia',
                              data: [0]
                             }],



             heightRalenti:230,
                seriesRalenti:[{
                    name: 'Hrs Ralenti',
                    data: []
                }],

                chartOptionsRalenti:{
                                        chart: {
                                            type: 'bar',
                                            height:230
                                            },
                                            plotOptions: {
                                            bar: {
                                                horizontal: true,
                                                columnWidth: '55%',
                                                endingShape: 'rounded'
                                            },
                                            },
                                            dataLabels: {
                                            enabled: false
                                            },
                                            stroke: {
                                            show: true,
                                            width: 2,
                                            colors: ['transparent']
                                            },
                                            xaxis: {
                                            categories: ['datos'],
                                            },
                                            yaxis: {
                                            title: {
                                                text: ''
                                            }
                                            },
                                            fill: {
                                            opacity: 1
                                            },
                                            tooltip: {
                                            y: {
                                                formatter: function (val) {
                                                return " " + val + ""
                                                }
                                            }
                                            }
                                        },




                                        seriesExcesos:[{
                                            name: 'Excesos ',
                                            data: []
                                        }],


                                        chartOptionsExcesos:{
                                          chart: {
                                            type: 'bar',
                                            height:230
                                            },
                                            plotOptions: {
                                            bar: {
                                                horizontal: true,
                                                columnWidth: '55%',
                                                endingShape: 'rounded'
                                            },
                                            },
                                            dataLabels: {
                                            enabled: false
                                            },
                                            stroke: {
                                            show: true,
                                            width: 2,
                                            colors: ['transparent']
                                            },
                                            xaxis: {
                                            categories: ['datos'],
                                            },
                                            yaxis: {
                                            title: {
                                                text: ''
                                            }
                                            },
                                            fill: {
                                            opacity: 1
                                            },
                                            tooltip: {
                                            y: {
                                                formatter: function (val) {
                                                return " " + val + ""
                                                }
                                            }
                                            }
                                        },



                                        seriesBotones:[{
                                        name: 'Botones',
                                        data: []
                                    }],

                chartOptionsBotones:{
                                        chart: {
                                            type: 'bar',
                                            height:260
                                            },
                                            plotOptions: {
                                            bar: {
                                                horizontal: true,
                                                columnWidth: '55%',
                                                endingShape: 'rounded'
                                            },
                                            },
                                            dataLabels: {
                                            enabled: false
                                            },
                                            stroke: {
                                            show: true,
                                            width: 2,
                                            colors: ['transparent']
                                            },
                                            xaxis: {
                                            categories: ['datos'],
                                            },
                                            yaxis: {
                                            title: {
                                                text: ''
                                            }
                                            },
                                            fill: {
                                            opacity: 1
                                            },
                                            tooltip: {
                                            y: {
                                                formatter: function (val) {
                                                return " " + val + ""
                                                }
                                            }
                                            }
                                        },
            //-------------------datos de severidad de exceso de velocidad-----------

          chartOptionsSeveridad:{
                                        chart: {
                                        type: 'bar',
                                        height: 320,
                                        width:600,
                                        stacked: true,
                                        toolbar: {
                                              show: false,
                                              },
                                         },
                                         colors: ['#56CA00', '#F44336',  '#DFCB5E', '#303C9B', '#FFFD95','#C6D57E','#ED50F1', '#A8E7E9','#57CC99'],
                                     
                                            plotOptions: {
                                            bar: {
                                                horizontal: true
                                            },
                                            },
                                            dataLabels: {
                                            enabled: false
                                            },
                                            stroke: {
                                            show: true,
                                            width: 2,
                                            colors: ['transparent']
                                            },
                                            xaxis: {
                                            categories: ['datos'],
                                            },
                                            yaxis: {
                                              showForNullSeries: false,
                                            title: {
                                                text: ''
                                            }
                                            },
                                            fill: {
                                            opacity: 1
                                            },
                                            tooltip: {
                                            y: {
                                                formatter: function (val) {
                                                return " " + val + ""
                                                }
                                            }
                                            },
                                            legend: {
                                        position: 'bottom',
                                        horizontalAlign: 'center',
                                        offsetX: 40,
                                        showForZeroSeries: false,
                                        showForNullSeries:false
                                      }
                                        },


                               seriesSeveridad:[{name:'vacio', data:[1]}],
                            

                              arregloviajes:[],

                              arregloDatosViajes:[],
                              arrayFlota:[],
                              clasificacionVelocidad:[],
                              ConductoresArray:[],
                              totalconductores:0,

                              search:'',
                              headers: [
                                  { text: 'Nombre conductor', value: 'conductorData[0].nombre_conductor' },
                                  { text: 'Codigo keypad', value: 'conductorData[0].codigo_keypad' },
                                  { text: 'Placa', value: 'vehiculoData[0].placa' },
                                  { text: 'Flota', value: 'vehiculoData[0].flota' },
                                  { text: 'Fecha', value: 'fecha' },
                                  { text:  'Distancia', value:  'distancia'},
                                  { text:  'Tiempo total', value:  'tiempo_total'},
                                  { text:  'Inicio', value:  'hora_inicio'},
                                  { text:  'Fin', value:  'hora_fin'},
                                  { text:  'Max velocidad', value:  'max_velocidad'},
                                  { text:  'Ralenti', value:  'tiempo_parado_enc'},

                                ],

                                    column_excel:[ 
                                      {label: "conductor",  field: "nombre_conductor"},
                                      {label: "Codigo keypad", field: "codigo_keypad"},
                                      {label: "Placa", field: "placa"},
                                      {label: "Flota", field: "flota"},
                                      {label: "Fecha", field: "fecha"},

                                      {label: "Distancia", field: "distancia"},
                                      {label: "tiempo_total",  field: "tiempo_total"},
                                      {label: "hora_inicio", field: "hora_inicio"},
                                      {label: "hora_fin", field: "hora_fin"},
                                      {label: "max_velocidad", field: "max_velocidad"}
                          ],


          numeroExcesos:0,
          dialogExceso:false,
          CategoriaGlobalExces:[],

          searchExcesos:'',
          headersExcesos:[
                    { text: 'Conductor', value: 'conductor' },
                    { text: 'Vehiculo', value: 'nombre' },
                    { text: 'Placa', value: 'placa' },
                    { text: 'Fecha', value: 'fecha' },
                    { text: 'Velocidad', value: 'Velocidad' },
                    { text: 'Severidad', value: 'Severidad' },
                    { text: 'Ubicacion', value: 'ubicacion' },
                    { text: 'Direccion', value: 'Direccion' }
                  ],
        itemsExceso:[],


        numeroBotones:0,
        dialogBotones:false,



        dialogRalenti:false,
        searchRalenti:'',
      
            headersRalenti:[
                { text: 'Conductor', value: 'conductorData[0].nombre_conductor' },
                { text: 'Vehiculo', value: 'vehiculoData[0].nombre' },
                { text: 'Placa', value: 'vehiculoData[0].placa' },

                { text: 'Fecha', value: 'fecha' },
                { text: 'Distancia', value: 'distancia' },
                { text: 'hrs ralenti', value: 'tiempo_parado_enc'},
          

                { text: 'Inicio', value: 'hora_inicio'},
                { text: 'Fin', value: 'hora_fin' },
                { text: 'tiempo_total', value: 'tiempo_total' }
              
              ],



              SelectConductor:[{text:'-Todos-', value:1}],
              id_conductor_vista:1,
              datosTabla:[],


              headersBotones:[
                    { text: 'Conductor', value: 'conductor' },
                    { text: 'Vehiculo', value: 'nombre' },
                    { text: 'Placa', value: 'placa' },
                    { text: 'Fecha', value: 'fecha' },
                    { text: 'Velocidad', value: 'Velocidad' },
                    { text: 'Ubicacion', value: 'ubicacion' },
                    { text: 'Direccion', value: 'Direccion' }
                  ],
              ArrayBotones:[],
              searchBotones:'',
              datosTablaExcesos:[],
              id_conductor_vista_exceso:1


      }),

computed: {

  ...mapState(['token','usuario'])

},

watch: {


 },
  created () {

    this.listarPaises();

    },

    methods: {

         ...mapMutations(['mostrarLoading','ocultarLoading']),

            ...mapActions(['salir']),

               listarPaises () {

                                            
                        let config={headers:{token:this.token}};
                        this.mostrarLoading({titulo:'Accediendo a datos'});

                        let me=this;

                        let RespuestaPaises=[];

                        axios.get('paises',  config
                        ).then(function(response){
                            // console.log(response);
                            RespuestaPaises=response.data;


                            me.configPaises=RespuestaPaises;

                            RespuestaPaises.map(function(x){
                                    me.PaisesArray.push({text:x.nombre_pais, value:x._id});
                                });

                                me.id_pais_activo=me.PaisesArray[0].value;

                                me.listarFlotas();
                             
                              

                            me.ocultarLoading();


                        }).catch(function(error){
                        console.log(error);
                        });
                },

                listarConductores(){ 


                  let respuesta=[];

                  this.totalconductores=0;


                        this.SelectConductor=[{text:'-Todos-', value:1}];
                        this.id_conductor_vista=1;
                        this.id_conductor_vista_exceso=1;


                          let config={headers:{token:this.token}};
                          this.mostrarLoading({titulo:'accediendo a datos'});
                          let me=this;

                          axios.get(`conductores/${this.id_pais_activo}`, config
                          ).then(function(response){

                            respuesta=response.data;


                            me.totalconductores=respuesta.length;

                            respuesta.map(function(a){
                                if(a.codigo_keypad!=1){
                                  me.ConductoresArray.push(a);
                                }
                                a.bandera_excesos=false;
                            });


                              me.ConductoresArray.map(function(x){
                                me.SelectConductor.push({text:x.nombre_conductor, value:x._id});
                              });


                              me.Procesar();


                              me.ocultarLoading();
                          }).catch(function(error){
                            console.log(error);
                        });

                },

                listarFlotas(){

                      this.arrayFlota=[];
                      this.clasificacionVelocidad=[];

                      this.mostrarLoading({titulo:'Accediendo a datos'});
                      let config={headers:{token:this.token}};
                      let me=this;
                      axios.get(`flotas/${this.id_pais_activo}`,
                        config
                        ).then(function(response){
                          
                            me.arrayFlota=response.data;
                            me.clasificacionVelocidad=me.arrayFlota[0].clasificacion_velocidad;

                          //  console.log('datos de clasificacion');

                           // console.log(me.clasificacionVelocidad);

                          //  console.log('fin de clasificacion');

                            me.ocultarLoading();
                            
                            me.listarConductores();

                            //  console.log(me.arrayFlota);
                        }).catch(function(error){ console.log(error); });


                },

                Procesar(){

                   this.consultarDatos(); 
                },
                consultarDatos(){


                   this.CategoriaGlobalExces=[];



                    let diseno=this.ObtenerDisenoSeveridad(1);
                    this.CategoriaGlobalExces.push({'nombre':'Grave', 'valor':0,'color':diseno.color, 'icono':diseno.icono});

                    diseno=this.ObtenerDisenoSeveridad(2);
                    this.CategoriaGlobalExces.push({'nombre':'Critico', 'valor':0,'color':diseno.color, 'icono':diseno.icono});
                  


                    this.arregloviajes=[];
                    this.numeroExcesos=0;
                    this.numeroBotones=0;

                                    let config={headers:{ token:this.token}};
                                    let me=this;

                                    axios.post('eventos-viajes',{ 
                                                    'id_pais':me.id_pais_activo,
                                                    'desde':this.fecha_inicio,
                                                    'hasta':this.fecha_fin
                                                    },
                                                config
                                                ).then(response=>{

                                                    let respuestas_=response.data;

                                                    me.arregloviajes=response.data;

                                                    let labels=[];
                                                    let valoreskm=[];
                                                    let valores_ralenti=[];
                                                    let valores_excesos=[];
                                                    let valores_botones=[];


                                                    respuestas_.map(function(r){

                                                        labels.push(r.nombre_conductor);

                                                        let distancia=Number(r.distancia);
                                                        distancia=distancia.toFixed(2);
                                                        valoreskm.push(distancia);

                                                        let ralentii=Number(r.ralenti);
                                                        ralentii=ralentii.toFixed(2);

                                                        valores_ralenti.push(ralentii);
                                                        valores_excesos.push(r.excesos);
                                                        valores_botones.push(r.botones);


                                                        me.numeroExcesos=parseInt(me.numeroExcesos)+parseInt(r.excesos);

                                                        me.numeroBotones=parseInt(me.numeroBotones)+parseInt(r.botones);

                                                        });






                                                    me.seriesKM=[{
                                                    name: 'Distancias',
                                                    data: valoreskm
                                                    }];

                                                    



                                    me.chartOptionsKM={
                                                    chart: {
                                                        type: 'bar',
                                                        height:230
                                                        },
                                                        
                                                        plotOptions: {
                                                        bar: {
                                                            horizontal: false,
                                                            columnWidth: '55%',
                                                            endingShape: 'rounded'
                                                        },
                                                        },
                                                        dataLabels: {
                                                        enabled: true
                                                        },
                                                        stroke: {
                                                        show: true,
                                                        width: 2,
                                                        colors: ['transparent']
                                                        },
                                                        xaxis: {
                                                        categories: labels,
                                                        },
                                                        yaxis: {
                                                        title: {
                                                            text: 'Kilometros'
                                                        }
                                                        },
                                                        fill: {
                                                        opacity: 1
                                                        },
                                                        tooltip: {
                                                        y: {
                                                            formatter: function (val) {
                                                            return " " + val + ""
                                                            }
                                                        }
                                                        }
                                                    };


                     me.seriesRalenti=[{
                        name: 'Hrs Ralenti',
                        data: valores_ralenti
                    }];

                    me.chartOptionsRalenti={
                                            chart: {
                                                type: 'bar',
                                                height:240
                                                },
                                                colors:['#FF4560', '#FEB019', '#F9DFDC'],
                                                plotOptions: {
                                                bar: {
                                                    horizontal: true,
                                                    columnWidth: '55%',
                                                    endingShape: 'rounded'
                                                },
                                                },
                                                dataLabels: {
                                                enabled: true
                                                },
                                                stroke: {
                                                show: true,
                                                width: 2,
                                                colors: ['transparent']
                                                },
                                                xaxis: {
                                                categories: labels,
                                                },
                                                yaxis: {
                                                title: {
                                                    text: ''
                                                }
                                                },
                                                fill: {
                                                opacity: 1
                                                },
                                                tooltip: {
                                                y: {
                                                    formatter: function (val) {
                                                    return " " + val + ""
                                                    }
                                                }
                                                }
                                            };



                                            
                                    me.seriesExcesos=[{
                                                  name: 'Excesos',
                                                  data: valores_excesos
                                                  }];



                                  me.chartOptionsExcesos={
                                                  chart: {
                                                      type: 'bar',
                                                      height:230
                                                      },
                                                      colors:[ '#FEB019', '#F9DFDC'],
                                                      plotOptions: {
                                                      bar: {
                                                          horizontal: false,
                                                          columnWidth: '55%',
                                                          endingShape: 'rounded'
                                                      },
                                                      },
                                                      dataLabels: {
                                                      enabled: true
                                                      },
                                                      stroke: {
                                                      show: true,
                                                      width: 2,
                                                      colors: ['transparent']
                                                      },
                                                      xaxis: {
                                                      categories: labels,
                                                      },
                                                      yaxis: {
                                                      title: {
                                                          text: ''
                                                      }
                                                      },
                                                      fill: {
                                                      opacity: 1
                                                      },
                                                      tooltip: {
                                                      y: {
                                                          formatter: function (val) {
                                                          return " " + val + ""
                                                          }
                                                      }
                                                      }
                                                  };



                                                  me.seriesBotones=[{
                                                        name: 'Botones',
                                                        data: valores_botones
                                                    }];

                    me.chartOptionsBotones={
                                            chart: {
                                                type: 'bar',
                                                height:260
                                                },
                                               
                                                colors:['#00E396', '#FEB019', '#F9DFDC'],
                                                plotOptions: {
                                                bar: {
                                                    horizontal: true
                                                },
                                                },
                                                dataLabels: {
                                                enabled: true
                                                },
                                                stroke: {
                                                show: true,
                                                width: 2,
                                                colors: ['transparent']
                                                },
                                                xaxis: {
                                                categories: labels,
                                                },
                                                yaxis: {
                                                title: {
                                                    text: ''
                                                }
                                                },
                                                fill: {
                                                opacity: 1
                                                },
                                                tooltip: {
                                                y: {
                                                    formatter: function (val) {
                                                    return " " + val + ""
                                                    }
                                                }
                                                }
                                            };








                                                    me.ocultarLoading();
                                                    me.dialogInicio=false;

                                                    me.listarDetalleViajes();


                                                }).catch(e=>{ console.log(e) });         


                },

                listarDetalleViajes(){

                    this.seriesSeveridad=[];

                    this.itemsExceso=[];
                    this.ArrayBotones=[];
                    this.datosTablaExcesos=[];


                                    this.arregloDatosViajes=[];

                                    let config={headers:{ token:this.token}};
                                    let me=this;

                                    axios.post('listado-viajes',{ 
                                                    'id_pais':me.id_pais_activo,
                                                    'desde':this.fecha_inicio,
                                                    'hasta':this.fecha_fin
                                                    },
                                                config
                                                ).then(response=>{

                                                    let respuestas_=response.data;

                                                   // console.log(respuestas_);

                                              

                                                    respuestas_.map(function(s){
                                                      s.nombre_conductor=s.conductorData[0].nombre_conductor;
                                                      s.codigo_keypad=s.conductorData[0].codigo_keypad;
                                                      s.vehiculo=s.vehiculoData[0].nombre;
                                                      s.placa=s.vehiculoData[0].placa;
                                                      s.flota=s.vehiculoData[0].flota
                                                      s.fecha=me.getDate(s.fecha);

                                                      if(parseInt(s.cantidad_botones)>0){

                                                        let eventos=s.eventos_botones;

                                                                         
                                                            eventos.map(function(a){
                                                        
                                                                    me.ArrayBotones.push({'conductor':s.nombre_conductor,'nombre':s.vehiculoData[0].nombre,
                                                                                           'placa':s.vehiculoData[0].placa,'fecha':me.getDateTim(a.fecha),'Velocidad':a.velocidad, 
                                                                                          'Direccion': a.direccion,'latitud':a.latitud, 'longitud':a.longitud  });
                                                     
                                                     
                                                               }); //fin dempa de btones            
                                                                                      
                                                                                      
                                                                                      
                                                          }//fin si hya btones


                                                     });  //fin de map de cada viaje

                                                    


                                                    me.arregloDatosViajes=respuestas_;


                                                
                                              


                                                   me.clasificacionVelocidad.map(function(z){

                                                  //console.log('clasificacion');

                                                 // console.log(z.desde);
                                                 // console.log(z.hasta);

                                                    let arraylocal=[];
                                                 

                                                          me.ConductoresArray.map(function(x){

                                                         

                                                                 let contadorporconducto=0;

                                                                me.arregloDatosViajes.map(function(y){

                                                                
                                                               if(x._id==y.conductorData[0]._id){


                                                                   

                                                                  if(parseInt(y.cantidad_excesos)>0){


                                                                      x.bandera_excesos=true;


                                                                  
                                                                            let eventos=y.eventos_excesos;

                                                                         

                                                                            eventos.map(function(a){

                                                                           

                                                                                if((parseFloat(a.velocidad)  >= parseFloat(z.desde))&&(parseFloat(a.velocidad)  <=  parseFloat(z.hasta))){
                                                                                  contadorporconducto++;
                                                                                  //console.log(z.nombre + 'cantidad: '+contadorporconducto);

                                                                                      me.CategoriaGlobalExces.map(function(f){ 
                                                                                        if(f.nombre==z.nombre){
                                                                                          f.valor++;

                                                                                           me.itemsExceso.push({'id_conductor':x._id,'conductor':x.nombre_conductor,'nombre':y.vehiculoData[0].nombre,
                                                                                           'placa':y.vehiculoData[0].placa,'fecha':me.getDateTim(a.fecha),'Velocidad':a.velocidad, 
                                                                                           'Severidad':f.nombre,'Direccion': a.direccion,'latitud':a.latitud, 'longitud':a.longitud });
                                                                                          }
                                                                                      });


                                                                                }

                                                                              });


                                                                        }//fin si posee exceso de velocidad

                                                                   }//fin si es mismo piloto


                                                                });


                                                          

                                                          
                                                           
                                                              arraylocal.push(contadorporconducto);
                                                            

                                                      });// fin de map de conductiores



                                                    


                                                      me.seriesSeveridad.push({ name: z.nombre, data: arraylocal });

                                                    });//fin de map clasificacion velocidades



                                                    
                                                
                                                    let arregloseveridaduno= me.seriesSeveridad[0].data;
                                                    me.seriesSeveridad[0].data=[];

                                                   
                                                    let arregloseveridaddos=me.seriesSeveridad[1].data;
                                                    me.seriesSeveridad[1].data=[];
                                                   
                                                  
                                                    let labels=[];
                                                    let contadorRegistos=0;
                                                  
                                                    me.ConductoresArray.map(function(b){ 


                                                      if(b.bandera_excesos==true){
                                                        labels.push(b.nombre_conductor);
                                                        me.seriesSeveridad[0].data.push(arregloseveridaduno[contadorRegistos]);
                                                        me.seriesSeveridad[1].data.push(arregloseveridaddos[contadorRegistos]);


                                                      }

                                                     

                                                      contadorRegistos++;
                                                    });


                                                   


                                               


                                                    console.log(labels);

                                                    
                                                    me.chartOptionsSeveridad={
                                                        chart: {
                                                            type: 'bar',
                                                            height: 320,
                                                            width:550,
                                                              toolbar: {
                                                                show: false,
                                                                },
                                                                colors: ['#56CA00', '#F44336', '#DFCB5E', '#303C9B', '#FFFD95','#C6D57E','#ED50F1', '#A8E7E9','#57CC99'],

                                                          },
                                                          plotOptions: {
                                                            bar: {
                                                              horizontal: true,
                                                            
                                                            },
                                                          },
                                                          dataLabels: {
                                                            enabled: true
                                                          },
                                                          stroke: {
                                                            show: true,
                                                            width: 2,
                                                            colors: ['transparent']
                                                          },
                                                          xaxis: {
                                                            categories:labels,
                                                            labels: {
                                                                trim: true,
                                                                maxHeight: 145,
                                                              }
                                                          },
                                                          yaxis: {
                                                            showForNullSeries: true,
                                                            title: {
                                                              text: ''
                                                            }
                                                          },
                                                          fill: {
                                                            opacity: 1
                                                          },
                                                          tooltip: {
                                                            y: {
                                                              formatter: function (val) {
                                                                return " " + val + ""
                                                              }
                                                            }
                                                          }
                                                        };




                                                                    
                                        









                                             

                                                    me.ocultarLoading();

                                                    me.FiltrarViajes();
                                                    me.FiltrarExcesosCond();


                                                }).catch(function(error){
                                                console.log(error);
                                                });


                 },

                 getDate(evaluar) {
                let date = new Date(evaluar),
                year = date.getFullYear(),
                month = (date.getMonth() + 1).toString(),
                formatedMonth = (month.length === 1) ? ("0" + month) : month,
                day = date.getDate().toString(),
                formatedDay = (day.length === 1) ? ("0" + day) : day;
              return formatedDay + "-" + formatedMonth + "-" + year ;
                },

              getDateTim(evaluar) {
                let date = new Date(evaluar),
                year = date.getFullYear(),
                month = (date.getMonth() + 1).toString(),
                formatedMonth = (month.length === 1) ? ("0" + month) : month,
                day = date.getDate().toString(),
                formatedDay = (day.length === 1) ? ("0" + day) : day,
                hour = date.getHours().toString(),
                formatedHour = (hour.length === 1) ? ("0" + hour) : hour,
                minute = date.getMinutes().toString(),
                formatedMinute = (minute.length === 1) ? ("0" + minute) : minute,
                second = date.getSeconds().toString(),
                formatedSecond = (second.length === 1) ? ("0" + second) : second;
              return formatedDay + "-" + formatedMonth + "-" + year + " " + formatedHour + ':' + formatedMinute + ':' + formatedSecond;
              },


              ObtenerDisenoSeveridad(numero){


                        let color='primary';
                        let icono='error';

                        if(numero==0){
                          color='primary';
                          icono='error';
                        }else if(numero==1){
                          color='success';
                          icono='warning';
                        }else if(numero==2){
                          color='red';
                          icono='bus_alert';
                        }else if(numero==3){
                          color='yellow';
                          icono='report';
                        }else{
                          color='blue';
                          icono='fmd_bad';
                        }

                        let diseno={'color':color, 'icono':icono};

                        return(diseno);


              },

              FiltrarViajes(){



                    this.datosTabla=[]; 
                    let me=this;


                    if(this.id_conductor_vista==1){
                      this.datosTabla=this.arregloDatosViajes;
                  }else{


                    this.arregloDatosViajes.map(function(x){
                          if(x.conductorData[0]._id==me.id_conductor_vista){
                            me.datosTabla.push(x);

                          }
                    });



                   }


            
              },


              FiltrarExcesosCond(){

                this.datosTablaExcesos=[];

                let me=this;


               // me.itemsExceso.push({'id_conductor':x._id,

                     if(this.id_conductor_vista_exceso==1){
                          this.datosTablaExcesos=this.itemsExceso;
                      }else{


                    this.itemsExceso.map(function(x){
                          if(x.id_conductor==me.id_conductor_vista_exceso){
                            me.datosTablaExcesos.push(x);
                          }
                    });



                   }


              
               },

              openGoogleMap(item){
                const urlSuffix = item.latitud +','+ item.longitud;
                window.open(
                  "https://www.google.com/maps/search/?api=1&query=" + urlSuffix,
                  "_blank"
                );
              },

              ...mapActions(['salir']),

            cerrar(){
                this.dialogExit=false;
                localStorage.removeItem('usuario');
                localStorage.removeItem('token');
                
                this.salir();
              },

              CambiarVistaJornada(){

              this.$router.push('/jornadas'); 

              },




        }
}
</script>
